.grid {
  text-align: center;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: 0;
  grid-row-gap: 0;
  grid-template-areas:
    "main"
    "footer";
}

.wireframe__main {
  grid-area: main;
  min-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(250, 248, 245);
}

.wireframe__footer {
  grid-area: footer;
  height: 5vh;
  font-size: 10px;
  color: rgb(176, 176, 176);
  background-color: rgb(250, 248, 245);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wireframe__footer span {
  text-align: center;
}

.welcome {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 95vh;
}

.welcome__logo {
  margin-top: -20vh;
  height: auto;
  max-width: 6.25rem;
}

.welcome__text {
  text-align: center;
  font-size: 0.88rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.welcome__text p {
  font-size: 0.88rem;
  color: rgb(10, 31, 68);
}

.welcome__input {
  width: 50%;
  height: 3rem;
  border-radius: 8px;
  border: 1px solid rgb(230, 230, 230);
  color: rgb(10, 31, 68);
  line-height: 22px;
  letter-spacing: 24px;
  text-align: center;
}

.main_section_1 {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #b6e4ff 0%, #daf2ff 100%);
}

.main_section_1__image {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 11.5rem;
}

.main_section_1__text {
  font-size: 0.88rem;
  text-align: left;
  position: absolute;
  padding-left: 0.5rem;
  /* font-size: 1.5vw; */
}

.number {
  /* text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5); */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: rgb(32, 32, 32);
  font-family: "MetropolisExtraBold";
  font-size: 1.5rem;
  /* font-size: 6vw; */
}

.number sub {
  padding-left: 1px;
  vertical-align: baseline;
  font-size: 0.5rem;
}

.main_section_1_message__button {
  width: 85%;
  height: 3rem;
  font-size: 1rem;
  background-color: white;
  color: rgb(70, 187, 125);
  font-weight: bolder;
  text-align: center;
  border: none;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.leaflet-container {
  width: 100%;
  border: 6px solid white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  min-height: 40vh;
  height: auto;
}

.main_section_2 {
  width: 100%;
  padding: 1rem;
  background-color: rgb(214, 214, 214);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main_section_2__image {
  margin: 1rem 0px 1rem 0px;
  /* width: 18.13rem;
  height: 6.25rem; */
  width: 100%;
  height: auto;
}

.main_section_2 span {
  font-family: "MetropolisExtraBold";
  text-align: center;
  color: rgb(59, 59, 59);
}

.main_section_2 p {
  font-family: "MetropolisMedium";
  line-height: 2rem;
  font-size: 0.88rem;
  text-align: center;
  color: rgb(59, 59, 59);
}

.main_section_2__button {
  width: 12.5rem;
  height: 3rem;
  font-size: 1rem;
  color: white;
  background-color: rgb(70, 187, 125);
  text-align: center;
  font-weight: bolder;
  border: none;
  margin-top: 1rem;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.center_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.main_section_3 {
  width: 100%;
  padding: 1rem;
  background-color: #fcf9da;
}

.main_section_3 .number {
  color: rgb(94, 94, 94);
}

.main_section_3 p {
  color: rgb(124, 124, 124);
  font-size: 0.88rem;
}

.main_section_4 {
  width: 100%;
  padding: 1rem;
}

.main_section_4 p {
  text-align: center;
  font-size: 0.75rem;
  padding-bottom: 1.5rem;
  color: rgb(96, 96, 96);
}

.main_section_4__logo {
  /* width: 6.25rem;
  height: 6.25rem; */
  cursor: pointer;
  max-width: 6.25rem;
  width: 100%;
  height: auto;
}

.center img {
  /* width: 9.38rem;
  height: 8.75rem; */
  max-width: 10rem;
  width: 100%;
  height: auto;
}

.main_section_4__social {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.main_section_4__social_twitter {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.view_message__message {
  font-style: italic;
  padding: 1rem 0 1rem 0;
  text-align: center;
}

.add_message__input p {
  text-align: left;
  color: rgb(10 31 68);
  font-size: 1.19rem;
  font-family: "MetropolisExtraBold";
}

.add_message__input input {
  font-size: 0.81rem;
  width: 70vw;
  height: 2.5rem;
  margin-top: 1rem;
  padding-left: 0.5rem;
  color: rgba(10, 31, 68, 0.6);
  background-color: rgb(233, 232, 231);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
}

.add_message__input textarea {
  font-size: 0.81rem;
  width: 70vw;
  height: 7.25rem;
  margin-top: 1rem;
  color: rgba(10, 31, 68, 0.6);
  background-color: rgb(233, 232, 231);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  padding: 0.5rem;
  resize: none;
}

.add_message__button {
  margin-top: 1rem;
  width: 70vw;
  height: 3rem;
  font-size: 1rem;
  color: white;
  background-color: rgb(70, 187, 125);
  text-align: center;
  font-weight: bolder;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.disabled {
  background-color: rgb(206, 206, 206);
  color: white;
  cursor: not-allowed;
}

.loader {
  border: 0.25rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.25rem solid rgb(64, 168, 192);
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*
.chain_message {
  background: #faf8f5;
  padding: 10px 0px 10px 0px;
}

.clock {
  display: flex;
}

.clock div {
  height: 100px;
}

.clock_day {
  width: 30%;
  color: white;
  background: #51acb9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
